
const HalloweenAnimation = () => {

  const ghost = require("../../assets/images/seasonal/ghost.png")
  const ghostSmallv1 = require("../../assets/images/seasonal/ghost_small_v1.png")
  const ghostSmallv2 = require("../../assets/images/seasonal/ghost_small_v2.png")



  return (
    <div className="halloween">
      <img className="ghost" src={ghost} alt="" />
      <img className="ghost_1" src={ghostSmallv1} alt="" />
      <img className="ghost_2" src={ghostSmallv2} alt="" />
    </div>
  )
}

export default HalloweenAnimation