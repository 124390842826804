/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FaStar,
  FaFlag,
  FaInfoCircle,
  FaRegEdit,
  FaFileInvoice,
  FaQuestionCircle,
  FaWineBottle,
  FaWineGlassAlt,
  FaWineGlass,
  FaGlassCheers,
  FaGlassWhiskey,
  FaLemon,
  FaInfo
} from "react-icons/fa";

import {
  IoBeerOutline,
  IoFlowerOutline,
  IoClose,
  IoEyeOutline,
  IoEyeOffOutline,
  IoCloseCircleSharp,
  IoShareSocial
} from "react-icons/io5";

import { GiOrange } from "react-icons/gi";
import {
  CiImageOn,
  CiPizza
} from "react-icons/ci";

import {
  TbAlertTriangle,
  TbSoup,
  TbSteeringWheel
} from "react-icons/tb";

import {
  MdLocalCafe,
  MdLiquor,
  MdOutlineEmojiFoodBeverage
} from "react-icons/md";

import {
  AiFillLayout
} from "react-icons/ai";

import {
  BsCreditCard2BackFill,
  BsPlusCircleFill
} from "react-icons/bs";

import {
  GiFlowerPot,
  GiIceCubes,
  GiMeal,
  GiGlassShot,
  GiPirateCaptain,
  GiBrandyBottle,
  GiChiliPepper
} from "react-icons/gi";

import {
  GoAlert
} from "react-icons/go";

import {
  BiDrink
} from "react-icons/bi";

import { IoHome } from "react-icons/io5";
// Exported Icons
// REMEMBER TO ALSO ADD THIS INTO models.py
export const item_icons = {
  creditCard: BsCreditCard2BackFill,
  star: FaStar,
  flag: FaFlag,
  questionCircle: FaQuestionCircle,
  plusCircle: BsPlusCircleFill,
  infoCircle: FaInfoCircle,
  info: FaInfo,
  alert: GoAlert,
  pizza: CiPizza,
  localCafe: MdLocalCafe,
  soup: TbSoup,
  meal: GiMeal,
  glassShot: GiGlassShot,
  iceCubes: GiIceCubes,
  wineBottle: FaWineBottle,
  wineGlassAlt: FaWineGlassAlt,
  wineGlass: FaWineGlass,
  glassCheers: FaGlassCheers,
  pirateCaptain: GiPirateCaptain,
  brandyBottle: GiBrandyBottle,
  glassWhiskey: FaGlassWhiskey,
  liquor: MdLiquor,
  lemon: FaLemon,
  drink: BiDrink,
  steeringWheel: TbSteeringWheel,
  emojiFoodBeverage: MdOutlineEmojiFoodBeverage,
  beer: IoBeerOutline,
  chili: GiChiliPepper,
  flower1: GiFlowerPot,
  flower2: IoFlowerOutline,
  GiOrange: GiOrange,
  IoHome:IoHome
}