import HalloweenAnimation from "./HalloweenAnimation"
import WinterAnimation from "./WinterAnimation"
import AutumnAnimation from "./AutumnAnimation"

interface seasonType {
  season?: "SUMMER" | "WINTER" | "AUTUMN" | "HALLOWEEN" | null
}

const SeasonalAnimationWrap = (props: seasonType) => {

  const getAnimation = () => {
    switch (props.season) {
      case "WINTER":
        return <WinterAnimation />
      case "HALLOWEEN":
        return <HalloweenAnimation />
      case "AUTUMN":
        return <AutumnAnimation />
      default:
        return <></>
    }
  }
  return (
    <>
      { getAnimation() }
      {/* <HalloweenAnimation /> */}
      {/* <AutumnAnimation /> */}
    </>
  )
}

export default SeasonalAnimationWrap