import { BrowserRouter, Routes, Route  } from "react-router-dom";
import HomePage from "./pages/NewHomePage"
/* import HomePage from "./pages/HomePage" */
import ContactPage from "./pages/ContactPage"
import CookiesPage from "./pages/CookiesPage";
import LoginPage from "./pages/LoginPage"
import DashboardPage from "./pages/Admin/DashboardPage"
import DefaultLayout from "./layouts/DefaultLayout"
import AdminPanelLayout from "./layouts/AdminPanelLayout"
import { IoCloseCircleSharp } from "react-icons/io5"
//@ts-ignore
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import React from 'react';

import NewClientPageOnline from "./pages/Clients/NewClientPageOnline";

//@ts-ignore
import blogContent from "./content/Blog/blog.js"

// PIVAREN PREDNA HORA HUMENNE
//@ts-ignore
/* import prednaHoraLogo from './assets/images/clients/logos/predna_hora.jpg'
import { prednaHoraConfig } from "./config/clients/prednaHora";
import { prednaHoraMenu } from "./content/PrednaHora/menu"; */

// PIVAREN PREDNA HORA CONFIG
import { prednaHoraConfig } from "./config/clients/prednaHora";

// PANORAMA CAFFE & DRINK BAR
import { panoramaConfig } from "./config/clients/panorama";

// JAZZ CAFE HUMENNE
import { jazzCafeConfig } from "./config/clients/jazzCafe";

// OBYVACKA BAR HUMENNE
import { obyvackaBarConfig } from "./config/clients/obyvackaBar";

// PIVOVAR U MEDVEDA
import { mercyCoffeeConfig } from "./config/clients/mercyCoffee";

// PREVIEW
/* import { previewConfig } from "./config/clients/preview"; */

import { AuthProvider} from "./context/AuthContext"
import { justLovelyCafeConfig } from "./config/clients/justLovelyCafe";
import CookiesModal from "./components/cards/CookiesModal";

import { useCookies } from "react-cookie";
import BlogMain from "./components/blog/BlogMain";
function App() {

  const [cookie, setCookie] = useCookies(['mojeqr_allow_cookies']);
  const tagManagerArgs = {
    gtmId: 'GTM-WWBSMZL'
  }



  const handleForbidCookies = () => {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() - 1);

    setCookie("mojeqr_allow_cookies", undefined, { path: '/', expires: expirationDate });
    window.location.href = "/"

  }
  const handleAllowCookies = () => {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);

    setCookie("mojeqr_allow_cookies", "allowed", { path: '/', expires: expirationDate });
  }


  useEffect(() => {
    console.log(cookie)
    if (cookie.mojeqr_allow_cookies === "allowed") {
      TagManager.initialize(tagManagerArgs);
    }
  }, [cookie.mojeqr_allow_cookies]);

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<DefaultLayout />}>
            <Route index element={<HomePage />} />
            <Route path="sluzby" element={<HomePage />} />
            <Route path="klienti" element={<HomePage />} />
            <Route path="cennik" element={<HomePage />} />
            <Route path="kontakt" element={<ContactPage />} />
            <Route path="cookies" element={<CookiesPage forbidCookies={() => { handleForbidCookies() }} />} />
          </Route>

          <Route path="/blog" element={<DefaultLayout />}>
            <Route index element={<BlogMain blogContent={blogContent} />} />
            {
              blogContent.map((blog: {
                path: string,
                component: any,
              }, index: number) =>
                <Route key={index} path={blog.path} element={blog.component} />
              )
            }
          </Route>

          <Route path="/admin/" element={<AdminPanelLayout />}>
            <Route path="prihlasenie" element={<LoginPage/>} />
            <Route path="prehlad" element={<DashboardPage/>} />
          </Route>

          <Route
            element={
              <NewClientPageOnline
                clientName={prednaHoraConfig.name}
                localConfig={prednaHoraConfig}
                localMenu={[]}
                /* browser top and bottom part color */
                localBackgroundColor={prednaHoraConfig.localBackgroundColor}
                localClientLogo={prednaHoraConfig.logo}
                path={prednaHoraConfig.path}
              />
            }
            path={prednaHoraConfig.path}
          />

          <Route
            element={
              <NewClientPageOnline
                clientName={jazzCafeConfig.name}
                localConfig={jazzCafeConfig}
                localMenu={[]}
                /* browser top and bottom part color */
                localBackgroundColor={jazzCafeConfig.localBackgroundColor}
                localClientLogo={jazzCafeConfig.logo}
                path={jazzCafeConfig.path}
              />
            }
            path={jazzCafeConfig.path}
          />


          <Route
            element={
              <NewClientPageOnline
                clientName={panoramaConfig.name}
                localConfig={panoramaConfig}
                localMenu={[]}
                /* browser top and bottom part color */
                localBackgroundColor={panoramaConfig.localBackgroundColor}
                localClientLogo={panoramaConfig.logo}
                path={panoramaConfig.path}
              />
            }
            path={panoramaConfig.path}
          />


          <Route
            element={
              <NewClientPageOnline
                clientName={obyvackaBarConfig.name}
                localConfig={obyvackaBarConfig}
                localMenu={[]}
                /* browser top and bottom part color */
                localBackgroundColor={obyvackaBarConfig.localBackgroundColor}
                localClientLogo={obyvackaBarConfig.logo}
                path={obyvackaBarConfig.path}
              />
            }
            path={obyvackaBarConfig.path}
          />

          <Route
            element={
              <NewClientPageOnline
                clientName={mercyCoffeeConfig.name}
                localConfig={mercyCoffeeConfig}
                localMenu={[]}
                /* browser top and bottom part color */
                localBackgroundColor={mercyCoffeeConfig.localBackgroundColor}
                localClientLogo={mercyCoffeeConfig.logo}
                path={mercyCoffeeConfig.path}
              />
            }
            path={mercyCoffeeConfig.path}
          />

          <Route
            element={
              <NewClientPageOnline
                clientName={justLovelyCafeConfig.name}
                localConfig={justLovelyCafeConfig}
                localMenu={[]}
                /* browser top and bottom part color */
                localBackgroundColor={justLovelyCafeConfig.localBackgroundColor}
                localClientLogo={justLovelyCafeConfig.logo}
                path={justLovelyCafeConfig.path}
              />
            }
            path={justLovelyCafeConfig.path}
          />










        </Routes>
        { cookie.mojeqr_allow_cookies !== "allowed" &&
          <CookiesModal
            allowCookies={() => {handleAllowCookies()}}
          />
        }
        <div
          id="IMAGE_MODAL"
          className="imageModal"
        >
          <div id="IMAGE_MODAL_CLOSE_BUTTON_WRAP">
            <IoCloseCircleSharp
              id="IMAGE_MODAL_CLOSE_BUTTON"
              size={"35px"}
            />
          </div>
          <div className="imageModal_image">
            {/* eslint-disable-next-line */}
            <h2 id="IMAGE_MODAL_TITLE"></h2>
            <img
              id="IMAGE_MODAL_IMAGE"
              alt="MojeQr"
            />
            <div id="IMAGE_MODAL_EXPANDABLE_CONTENT" />
            <div id="IMAGE_MODAL_LEFT"></div>
            <div id="IMAGE_MODAL_RIGHT"></div>
          </div>
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
