import { IoMdArrowRoundBack } from "react-icons/io"


export const prednaHoraConfig ={
  name: "Piváreň Predná Hora",
  path: "predna_hora",
  menu: [],
  /* default logo './assets/images/clients/logos/just_lovely_cofe.png' */
  /* logo: require("../../assets/images/clients/logos/just_lovely_cofe.png"), */
  /* logo: require("../../assets/images/clients/logos/halloween/just_lovely_cofe_halloween.png"), */
  logo: require("../../assets/images/clients/logos/predna_hora.jpg"),

  // after click on section screen scrolls to that section
  autoscrollAfterClick: false,
  scrollToTopAferOpen: true,
  // left alignment if displayed in rows
  alignTitleToLeft: false,
  //scroll time of scroll to section
  autoscrollTimeInMs: 100,
  //scroll to top of page after closing section
  scrollToTopAfterClose: true,
  //icon that appears in corner when section is opened
  hideAllIcon: IoMdArrowRoundBack,
  //decides if other sections should dissapear when section is opened
  hideAllWhenOneOpen: true,
  //vibrate on click on section
  vibrateOnClick: false,
  // close previous section when new section is opened
  closePreviousSectionOn: true,
  //margin top to display all sections
  displayAllOnPhone: false,
  //contact icon color
  iconColor: "#ffffff",
  localBackgroundColor: "#1C1A1F",
  //links to social sites
  facebook: "https://www.facebook.com/profile.php?id=100041946254993",
  instagram: "https://www.instagram.com/pivaren_prednahora_humenne/",
  email: "pivarenprednahora@gmail.com",
  phone: "+421 911 299 111",

  //styles
  mainWrapClass: "prednaHora",
  bottomNote: ""
  /*
  logoBorderRadius: "8px",
  background: "#CFCAC4",
  blockBackgroundColor: "#878787",
  dividerColor: "#F7C808", */

}