import { useEffect } from "react"
import { useNavigate } from "react-router-dom";
const BlogMain = (props: any) => {


  const navigate = useNavigate()

  useEffect(() => {
    document.title = "MojeQR - Blog"
    document.getElementsByTagName('meta')[3].content = "Jednoduché a moderné QR menu pre reštaurácie, bary a kaviarne. Vytvorte si digitálne menu s možnosťou rýchlych úprav a bezkontaktnej obsluhy. MojeQR – praktické, rýchle a cenovo dostupné riešenie pre váš podnik."
    document.getElementsByTagName('link')[2].href = "https://mojeqr.sk/blog"
  },[])

  return (
    <div
      className="blogCard"
    >
      <h1 className="news_room_title">Aktuality a blog</h1>
      <div className="latest_news_wrap">

        <div className="latest_news_card" onClick={() => {navigate(`/blog/${props.blogContent[0].path}`)}}>
          <div className="row">
            <div className="col_1">
              <div className="top_content">
                <span className="title">
                  { props.blogContent[0].title }
                </span>
                <span className="meta_desr">
                { props.blogContent[0].short_description }
                </span>
              </div>
              <div className="bottom_content">
                <span className="date">
                  { props.blogContent[0].date }
                </span>
              </div>
            </div>
            <div className="col_2">
            { props.blogContent[0].image }
            </div>
          </div>
        </div>
      </div>

      <div className="action_bar_wrap">
        action_bar
      </div>

      <div className="blog_list_wrap_wrap">
        {
          props.blogContent.map((article: any, index: number) =>
            <div>
              article {index + 1}
            </div>
          )
        }
      </div>

    </div>
  )
}

export default BlogMain