import { IoMdAdd } from "react-icons/io";
import { FaQuestionCircle } from "react-icons/fa";
import { FaInfo } from "react-icons/fa";

const HowToMoveLastItem = () => {
  return (
    <>
      <div className="how_to_element_wrap">
        <div className="transparent_card">
          <div className="subtitle align-center">
            <FaInfo  size={"30px"} style={{marginRight: "10px"}} />
            Zavadzia vám tlačidlo "Vytvoriť položku" v sekcii Editácia ponuky ?
          </div>
        </div>
        <div className="card how_to">
          <div className="text">
            Pri presúvaní poslednej položky v zozname sa môže stať, že vám bude zavadzať tlačidlo <IoMdAdd size={"15px"} style={{marginLeft: "2px"}} /> v pravom dolnom rohu.
          </div>
          <div className="text">
            Dočasne ho môžete skryť tým, že podržíte klávesu ctrl a medzerník.
          </div>
        </div>
      </div>
      <div className="how_to_element_wrap">
        <div className="transparent_card">
          <div className="subtitle align-center">
            <FaInfo  size={"30px"} style={{marginRight: "10px"}} />
            Máte problémy s editáciou rozloženia ?
          </div>
        </div>
        <div className="card how_to">
          <div className="text">
            Uistite sa prosím že problémový blok nieje schovaný (dvojklik na blok v náhľade)
          </div>
          <div className="text">
            Ak vám aj tak nesedí rozloženie, skúste odstrániť všetky sekcie z náhľadu a nahodiť ich naspäť.
          </div>
          <div className="text">
            Ak problém pretrváva - prosím kontaktujte ma na info@mojeqr.sk alebo na sociálnych sieťach.
          </div>
        </div>
      </div>
    </>
  )
}

export default HowToMoveLastItem