import { FiCopy } from "react-icons/fi";
import { FaRegEdit, FaFileInvoice, FaFacebookSquare, FaQuestionCircle } from "react-icons/fa";
import { AiFillLayout, AiOutlineLoading } from "react-icons/ai";
import { IoStatsChart } from "react-icons/io5";
import { RiInstagramFill } from "react-icons/ri";
import { SiGmail } from "react-icons/si";
import { ImMobile } from "react-icons/im";
import { FaShoppingCart } from "react-icons/fa";

import { invokeAlertComponent } from "../../services/userInteractionService";

import { copyToClipboard } from "../../services/userInteractionService";

import { ClientSettingsCardProps, OpenedSettingsTabIndexType } from "../../PropTypes/AdminDashboardProps";
import { useAuth } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import SelectorElement from "./SelectorElement";


const NavigationCard = (props: ClientSettingsCardProps) => {

  //@ts-ignore
  const { user } = useAuth()


  const mojeqr_logo = require('../../assets/images/logo.jpg')
  const [logoBadgeURL, setLogoBadgeURL] = useState(undefined)

  const getTopMargin = (selectedTab: OpenedSettingsTabIndexType) => {
    switch (selectedTab) {
      case 0:
        return 8
      case 1:
        return 41
      case 2:
        return 75
      case 3:
        return 109
      case 4:
        return 143
      case 5:
        return 177
      default:
        break;
    }
  }

  const sendMail = () => {
    var link = "mailto:info@mojeqr.sk"
             + "&subject=" + encodeURIComponent("MojeQR - Ovládací panel požiadavka")
    ;

    window.location.href = link;
}

  const settings_elements = [
    {
      title: "Editácia ponuky",
      onClick: () => { props.onPriceEditClick() },
      icon: <FaRegEdit size={"20px"}/>
    },
    {
      title: "Editácia rozloženia",
      onClick: () => { props.onLayoutEditClick() },
      icon: <AiFillLayout size={"20px"}/>
    },
    {
      title: "Ponuky",
      onClick: () => { props.onImageGalleryClick() },
      icon: <FaShoppingCart   size={"20px"}/>
    },
    {
      title: "Štatistiky",
      onClick: () => { props.onContactEditClick() },
      icon: <IoStatsChart  size={"20px"}/>
    },
    {
      title: "Faktúry a predplatné",
      onClick: () => { props.onInvoiceSubscriptionClick() },
      icon: <div className="menu_element"><FaFileInvoice  size={"20px"}/>{props.isUnpaidInvoice && <div className="invoice_alert"></div>}</div>
    },
    {
      title: "Ako na to",
      onClick: () => { props.onHowToClick() },
      icon: <FaQuestionCircle  size={"20px"}/>
    },
  ]

  useEffect(() => {
    if (props.businessEstablishment) {
      setLogoBadgeURL(props.businessEstablishment.logo)
    }
  }, [props.businessEstablishment])


  return (
    <>
      <div className="logo_wrap">
        <div
          className="logo_bg"
          style={user?.is_superuser ? {background: "transparent"} : {}}
        >
          {user?.is_superuser ? "" : <img src={mojeqr_logo} alt="MojeQr logo" className="mojeqr_logo" />}
          <img
            src={user?.is_superuser ? mojeqr_logo : `${process.env.REACT_APP_MOJEQR_API_URL}${logoBadgeURL}`}
            style={user?.is_superuser ? {width: "90%"} : {}}
            alt="Client logo"
            className="client_logo"
          />

        </div>
        <div
          id="ADMIN_PANEL_LOADING_INDICATOR"
          className="loading_wrap"
        >
          {/* <span>Načítavam zmeny</span> */}
          <AiOutlineLoading
            id="ADMIN_PANEL_LOADING_INDICATOR"
            size={"35px"}
            color="#2C2C2C"
            /* color="white" */
            className="loading_icon_local"
          />
        </div>
      </div>
      <div className="name_wrap">
        <div className="name">
          { `${user?.first_name} ${user?.last_name}` }
        </div>
        <div className="email">
          {/* { user?.email } */}
          info@mojeqr.sk
          <FiCopy
            className="icon"
            /* onClick={() => { copyToClipboard(user?.email) }} */
            onClick={() => {
              copyToClipboard("info@mojeqr.sk")
              invokeAlertComponent("INFORMATION_ALERT_COMPONENT", `Skopírovali ste emailovu adresu ${"info@mojeqr.sk"}`)
            }}
          />
          <SiGmail
            className="icon"
            /* onClick={() => { copyToClipboard(user?.email) }} */
            onClick={() => { sendMail() }}
          />
          <FaFacebookSquare
            className="icon"
            /* onClick={() => { copyToClipboard(user?.email) }} */
            onClick={() => { window.open("https://www.facebook.com/profile.php?id=100089698576173") }}
          />
          <RiInstagramFill
            className="icon"
            /* onClick={() => { copyToClipboard(user?.email) }} */
            onClick={() => { window.open("https://www.instagram.com/mojeqr/") }}
          />
        </div>
      </div>
      <div className="settings_wrap">
        <div className="title_wrap">
          Nastavenia
        </div>
        <div className="settings_elements_wrap">
          <div
            className="settings_element_active_indicator"
            style={{
              top: `${getTopMargin(props.activeTabNumber)}px`
            }}
          />
          {
            settings_elements.map((option, index) =>
              <div
                className={`settings_element ${index === props.activeTabNumber ? "settings_element_active" : ""}`}
                key={index}
                onClick={option.onClick}
              >
                {option.icon}
                {option.title}
              </div>
            )
          }
        </div>
      </div>
      <div
        className="settings_element"
        onClick={() => {
          window.open(`${window.location.origin}/${props.businessEstablishment.path}`)
        }}
      >
        <ImMobile size={"20px"} />
        Zobraziť náhľad
      </div>
      {
        user?.is_superuser &&
        <div className="client_list_wrap">
          <div className="field_element" style={{ width: "250px"}}>
            <SelectorElement
              selectedElement={props.businessEstablishment?.uuid}
              list={
                props.clients.map(client => {
                  return {
                      title: client.name,
                      value: client.uuid
                  }
                })
              }
              onSelect={(new_type) => { props.onSetCurrentClient(new_type)}}
            />

          </div>
        </div>
      }
    </>
  )
}

export default NavigationCard