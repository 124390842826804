
import { useEffect, useState } from "react"
import NewClientOnline from "../../components/clients/NewClientOnline"
import axios from 'axios';
import { BusinessEstablishment } from "../../PropTypes/AdminDashboardProps";
import { MenuConfigProp } from "../../PropTypes/MenuProps";
import { IoMdArrowRoundBack } from "react-icons/io"


interface localProps {
  path: string,
  localConfig: MenuConfigProp,
  localMenu?: object,
  localClientLogo: string,
  localBackgroundColor: string,
  clientName: string
}

const NewClientPageOnline = (props: localProps) => {

  const [localBackgroundColor] = useState(props.localBackgroundColor);
  const [clientName] = useState(props.clientName);
  const [localConfig, setLocalConfig] = useState<MenuConfigProp | null>(null);
  const [localMenu] = useState(props.localMenu);
  const [localClientLogo, setLocalClientLogo] = useState(props.localConfig.logo);
  const [businessEstablishmentData, setBusinessEstablishmentData] = useState<BusinessEstablishment | null>(null);

  // Function to get config (business establishment data)
  const getConfig = async () => {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MOJEQR_API_URL}/api/business-establishment-public/?path=${props.path}`,
        { headers }
      );
      setBusinessEstablishmentData(response.data);
    } catch (error) {
      console.error('Error fetching config:', error);
    }
  };

  // Function to get the menu and modify it
  const getMenu = async (businessEstablishmentUUID: string) => {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MOJEQR_API_URL}/api/offer-blocks/?client_uuid=${businessEstablishmentUUID}`,
        { headers }
      );
      const modifiedMenu = modifyMenu(response.data);
      return modifiedMenu; // Return modified menu array
    } catch (error) {
      console.error('Error fetching menu:', error);
    }
  };

  // Function to modify the menu by grouping by rows
  const modifyMenu = (localMenu: any) => {
    let menu = localMenu;
    const modifiedMenu: { [key: string]: any[] } = {};

    menu.forEach((item: any) => {
      if (!modifiedMenu[item.block_position_row]) {
        modifiedMenu[item.block_position_row] = [];
      }
      if (item.block_is_visible) {
        modifiedMenu[item.block_position_row]
        .push(
          {
            ...item,
            isOpen: false,
            recommended_flag: businessEstablishmentData?.item_recommended_image,
            block: item.item_redirect_target,
            blockId: `${item.item_redirect_target_title}_ID`,
            blockTitle: item.item_redirect_target_title
          }
        );
      }
    });

    return Object.values(modifiedMenu);
  };

  // Set the config after the businessEstablishmentData is fetched
  const setConfig = async () => {
    if (businessEstablishmentData) {
      const loadedConfig = {
        uuid: businessEstablishmentData.uuid,
        menu: [], // Initial empty menu
        logo: `${process.env.REACT_APP_MOJEQR_API_URL}${businessEstablishmentData.logo}`,
        autoscrollAfterClick: businessEstablishmentData.autoscroll_after_click,
        scrollToTopAferOpen: businessEstablishmentData.scroll_to_top_after_open,
        alignTitleToLeft: businessEstablishmentData.align_to_left,
        autoscrollTimeInMs: businessEstablishmentData.autoscroll_time_in_ms,
        scrollToTopAfterClose: businessEstablishmentData.scroll_to_top_after_close,
        hideAllIcon: IoMdArrowRoundBack,
        hideAllWhenOneOpen: businessEstablishmentData.hide_all_when_one_open,
        closePreviousSectionOn: businessEstablishmentData.close_previous_section_on,
        displayAllOnPhone: businessEstablishmentData.display_all_on_phone,
        // Social media links
        facebook: businessEstablishmentData.facebook_url,
        instagram: businessEstablishmentData.instagram_url,
        email: businessEstablishmentData.email_address,
        iconColor: businessEstablishmentData.social_icon_color,
        // Styles
        mainWrapClass: businessEstablishmentData.UI_class,
        item_flag_1: businessEstablishmentData.item_flag_1,
        item_flag_2: businessEstablishmentData.item_flag_2,
        item_flag_3: businessEstablishmentData.item_flag_3,
        //@ts-ignore
        ui_mode: businessEstablishmentData.ui_mode,
        //@ts-ignore
        scss_file: `${process.env.REACT_APP_MOJEQR_API_URL}${businessEstablishmentData.scss_file}`,
        //@ts-ignore
        css_url: `${process.env.REACT_APP_MOJEQR_API_URL}${businessEstablishmentData.css_url}`
      };

      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = loadedConfig.css_url;
      document.head.appendChild(link)

      // Get the menu using the UUID from businessEstablishmentData
      const menu = await getMenu(loadedConfig.uuid);
      //@ts-ignore
      loadedConfig.menu = menu;

      // Set the config with the loaded menu
      setLocalConfig(loadedConfig)
    }
  };


  useEffect(() => {
    const meta = document.querySelector('meta[name="theme-color"]');
    if (meta) {
      meta.setAttribute('content', localBackgroundColor);
    } else {
      const newMeta = document.createElement('meta');
      newMeta.setAttribute('name', 'theme-color');
      newMeta.setAttribute('content', '#000000');
      document.head.appendChild(newMeta);
    }
  }, []);
  // Fetch the config when the component mounts
  useEffect(() => {
    getConfig();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Once businessEstablishmentData is fetched, set the full config
  useEffect(() => {
    if (businessEstablishmentData) {
      setLocalClientLogo(`${process.env.REACT_APP_MOJEQR_API_URL}${businessEstablishmentData.logo}`);
      setConfig(); // Call setConfig to update the config and menu
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessEstablishmentData]);
  useEffect(()=> {

    document.body.style.backgroundColor = localBackgroundColor
    window.scrollTo(0,0)
    document.title = `MojeQr - ${clientName}`
    document.getElementsByTagName('meta')[3].content = `${clientName} využíva službu MojeQR. Mrkni sa sem a v prípade záujmu nás neváhaj kontaktovať na +421 940 850 530 alebo emailom na info@mojeqr.sk. Čakáme na teba!`

    document.getElementsByTagName('link')[2].href = `https://www.mojeqr.sk/${props.path}`
  }, [clientName, localBackgroundColor, props.path])

  return (
    <>
      {
        (localMenu && localConfig) &&
        <NewClientOnline
          localConfig={localConfig}
          localMenu={localConfig.menu}
          localClientLogo={localClientLogo}
        />
      }

    </>
  )
}

export default NewClientPageOnline

